import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import details1 from '../../assets/images/projects/cc.png'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Contaminiamoci di Cultura" 
                homePageText="Social balance and Monitoring and Evaluation" 
                homePageUrl="/social-balance-monitoring-evaluation" 
                activePageText="Contaminiamoci di Cultura" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://www.progettointegrazione.org/" target="_blank" rel="noreferrer">Progetto Integrazione onlus</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
	    		Italy
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technology:</span>
                       Dino 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-money'></i>
                        </div>
                        <span>Donor:</span>
	    	 Fondazione Monza e Brianza	
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        2 year
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status</span>
                            On going
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Digital Transformation</span>
                            <h3>The project</h3>
                            <p>
The "Contaminiamoci di Cultura" project aims to reduce educational poverty in the Monza, Desio and Vimercate areas by strengthening the action of the network of actors in the area and their virtuous interaction with local institutions, bringing out models and good practices that can be extended to other territories and in other areas. To achieve this goal, a coordination structure will be created within the project among the network actors, which will be called the "system table."<br/>
The table will coordinate the monitoring and evaluation activities of the project with survey tools that will be implemented on a data collection platform, made available by Gnucoop. Data collection will take place during the execution of project activities and will allow for a quantitative and qualitative measure of the impact achieved.

			    </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

